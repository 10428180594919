<template>
    <RouterView
        @set-title="$emit('set-title', $event)"
    />
</template>

<script>
    export default {
        name: 'PBase',
    };
</script>
